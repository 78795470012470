<template>
<div>
 <van-nav-bar
      class="backBox"
      title="服务变更"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

  <div class="page">

    <div class="mainWrap">
      <div class="box_wrap">
        <div class="inner_box">
          <div class="showItem" v-for="item in  monthCarList" :key="item.id">
          <div class="box">
            <div class="box_header">
              <span class="title">现有服务：</span>
              <!-- <span class="type_date">服务开始时间：{{ item.create_time }} </span> -->
              <span class="type_name"
                >{{item.vh_cate_name}}/{{item.vh_type_name}}/{{item.month_rent/100}}元</span
              >
              <!-- <span class="type_name" v-if="serveInfo.type == 2"
                >工作日白天包月卡/300元</span
              > -->
              <!-- <span class="type_name" v-if="serveInfo.type == 3"
                >夜间包月卡/150元</span
              > -->
              <span class="type_date">{{ item.effective_date_end }} 到期</span>
            </div>
            <span class="btn_text" @click="openRenew(item)">续费</span>
          </div>
          <div class="lineline"></div>
          <div class="vh_box">
            <!-- <span class="type_name">使用车牌：{{ columnsSel }}</span> -->
            <span class="type_name">使用车牌：{{ item.plate_number }}</span>
            <!-- 原生picker -->
            <!-- <picker bindchange="bindPickerChange" value="{{index}}" range="{{array}}">
        <div class="picker">
          使用车牌：{{array[index]}}
        </div>
      </picker> -->
            <!-- <van-cell title="展示弹出层" is-link @click="showPopup" /> -->

            <!-- van picker -->
            <span class="btn_text" is-link @click="showPopup(item)">修改</span>
          </div>
          <div class="lineline"></div>
          </div>
          <div class="tip_box">
            <span class="tip_box_tit">温馨提示：</span>
            <span class="tip_box_text"
              >1.套餐显示为单个车位的价格，支付总金额按照本次办理的约束<br />或者周数叠加计算</span
            >
          </div>
        </div>
      </div>
      <!-- <div class="pay_button"> -->
      <!-- <van-button
        class="pay_button"
        type="info"
        color="##4470FC"
        @click="onClickPay"
        >变更</van-button
      > -->
      <!-- </div> -->
    </div>
    <!-- 绑定手机 -->
    <!-- <bind-phone
      :show="show_bind_phone"
      @get-success="getBindPhoneSuccess"
      @get-on-close="getBindPhoneShow"
    ></bind-phone> -->
    <!-- 未授权或者未绑定手机的提示弹窗 -->
    <!-- <not-auth-or-phone
      :show="showPhonePopupInNotIndex"
      :show-type="showNotAuthPhoneType"
      @get-on-close="getNotAuthBindPhoneShow"
      @showBindPhone="showBindPhone"
    ></not-auth-or-phone> -->

     <van-popup
              v-model="showVhPopup"
              @close="onVhClose"
              position="bottom"
            >
              <van-picker
                :columns="vhColumns"
                @change="onVhChange"
                @confirm="onVhConfirm"
                @cancel="onVhCancel"
                show-toolbar
                title="选择车牌号码"
                visible-item-count="5"
                default-index="0"
              />
            </van-popup>
  </div>
  </div>
</template>
<script>
import checkPhoneMixin from '../../../mixins/checkPhone'
import BindPhone from '../../../components/BindPhone.vue'
import NotAuthOrPhone from '../../../components/NotAuthOrPhone.vue'
import * as api from '../../../api/api'
import * as constant from '../../../api/constant'
import * as common from '../../../api/common'
import * as util from '../../../api/util'
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'

import { Toast, Dialog } from 'vant'

export default {
  mixins: [checkPhoneMixin],
  components: { BindPhone, NotAuthOrPhone },
  data () {
    return {
      serveInfo: {
        // 预留 获取当前服务信息
        // type: 1,
        // type: 2,
        type: 3,
        deadLine: '2099-12-31 23:59:59'

      },
      columnsSel: '',
      showVhPopup: false,
      columns: [
        '浙A12345',
        '浙B12345',
        '浙C12345',
        '浙D12345',
        '浙F12345'
      ],
      showPhonePopupInNotIndex: false,
      show_bind_phone: false,
      showNotAuthPhoneType: 2,
      monthCarList: [],
      selItemData: {}, // 选中的对象数据备份
      sidLocal: 'yuen03'

    }
  },
  mounted () {
    // 每个页面都要 引入下面的混入
    // 监听混入中触发的事件，显示弹窗
    this.$on('showPhonePopup', () => {
      this.showPhonePopupInNotIndex = true
    })
    // ToDo 监听混入中触发的事件，手机号已绑定 则关闭打开的弹窗
    this.$on('phoneHaveBind', () => {
      if (this.show_bind_phone) {
        this.show_bind_phone = false
      }
      if (this.showPhonePopupInNotIndex) {
        this.showPhonePopupInNotIndex = false
      }
    })
    this.getMonthCarList()
  },
  computed: {
    ...mapGetters(['vhColumns', 'openID']),
    ...mapState({
      // payType: state => state.payType.payType,
      sid: state => state.sid.sid,
      appid: state => state.appid.appid
    })
  },
  methods: {
    async  getMonthCarList () {
      this.$toast.loading({
        mask: true,
        message: '请稍候...'
      })
      try {
        const { data } = await axios({
          url: common.iotUrl + '/uuid/' + api.urlList.car_owner.vh.get_bind_rent_vh,
          method: 'POST',
          data: {
            sid: this.sid || this.$route.query.sid,
            // sid: this.sidLocal,
            openid: this.openID,
            // openid: 'o1pxwuMcjjXt2YtCXSl3UC-ZbK-w',
            open_handle_vh_cate: 1, // 是否指定长租办理的车辆类别(8、9、10)，指定=1；不指定=0
            version: 'v2'
          },
          header: {
            'content-type': 'application/json' // 默认值
          }
        })
        console.log('openid res', data)
        Toast.clear()
        if (data.code === 10000) {
          this.monthCarList = data.list
        } else {
          Toast.fail('查询出错')
        }
      } catch (e) {
        this.$toast('查询异常')
      }
    },
    getBindPhoneSuccess () {

    },
    getBindPhoneShow () {

    },
    getNotAuthBindPhoneShow () {

    },
    showBindPhone () {
      this.show_bind_phone = true
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    // 续费
    openRenew (item) {
      console.log('即将续费的是', item)

      sessionStorage.setItem('renewParaStorage', JSON.stringify(item))
      this.$router.push('/monthlyRental/serviceChangeRenew')
    },
    showPopup (item) {
      console.log('准备修改的是哪个数据', item)
      this.selItemData = item
      this.showVhPopup = true
    },
    onClickPay () {

    },
    onVhChange () {

    },
    onVhConfirm (plateNum) {
      console.log('选择车牌', plateNum)
      console.log('选中的数据', this.selItemData)
      if (plateNum === this.selItemData.plate_number) {
        return this.$toast('不能选择同一车牌号码')
      }
      if (plateNum) {
        this.columnsSel = plateNum
        this.showVhPopup = false
        Dialog.confirm({
          title: '提示',
          message: '服务【' + this.selItemData.vh_cate_name + '】的车牌:' + this.selItemData.plate_number + '修改成' + plateNum + '，是否确认？',
          confirmButtonColor: '#4797EA'
        })
          .then(() => {
          // on confirm
            this.modifyVhNum(this.selItemData.plate_number, plateNum)
          })
          .catch(() => {
          // on cancel
          })
      }
    },
    async modifyVhNum (oldVhNumber, newVhNumber) {
      try {
        const { data } = await axios({
          url: common.iotUrl + '/uuid/' + api.urlList.car_owner.vh.modify_bind_rent_vh,
          method: 'POST',
          data: {
            sid: this.sid || this.$route.query.sid,
            // sid: this.sidLocal,
            openid: this.openID,
            // openid: 'o1pxwuMcjjXt2YtCXSl3UC-ZbK-w',
            plate_number: newVhNumber,
            old_plate_number: oldVhNumber,
            version: 'v2'
          },
          header: {
            'content-type': 'application/json' // 默认值
          }
        })
        console.log('openid res', data)
        if (data.code === 10000) {
          this.$toast('修改成功')
          // 重新获取列表
          this.getMonthCarList()
        } else {
          Toast.fail('修改出错', data.msg)
        }
      } catch (e) {
        console.log('modifyVhNum-catch:', e)
        this.$toast('修改异常', e)
      }
    },
    onVhCancel () {
      this.showVhPopup = false
      // 清除选中的数据
      this.selItemData = {}
    },
    onVhClose () {
      this.showVhPopup = false
    }

  }
}
</script>
<style lang="scss" scoped>
.page {

}
.mainWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f7fc;
  height: 100vh;
  padding: 0.16rem 0;
  overflow: hidden;
  // padding: 1rem;
  .box_wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    .inner_box {
      // padding: 0.16rem;
      background-color: #f8f7fc;
      .showItem{
        padding: 0.16rem;
        background-color: #fff;
        margin-bottom: 0.16rem;

      .box {
        // width: 90%;
        margin-right: 0.01rem;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        .box_header {
          display: flex;
          flex-direction: column;
          .title {
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 0.16rem;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            white-space: nowrap;
            line-height: 0.22rem;
            margin-right: 0.51rem;
          }
          .type_name {
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 0.14rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.2rem;
            margin-top: 0.16rem;
          }
          .type_date{
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 0.12rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.17rem;
            margin-top: 0.02rem;
          }
        }
        .btn_text {
          overflow-wrap: break-word;
          color: rgba(68, 112, 252, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
          margin-top: 0.36rem;
        }
      }
      .lineline {
        // width: 90%;
        height: 0.01rem;
        background-color: #f4f4f4;
        margin-top: 0.16rem;
      }
      .vh_box {
        // width: 90%;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.16rem 0.01rem 0 0;
        .type_name {
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
        }
        .btn_text {
          overflow-wrap: break-word;
          color: rgba(68, 112, 252, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
        }
      }

    }
     .tip_box {
        display: flex;
        flex-direction: column;
        padding: 0 0.16rem;
        // margin: 0.25rem 0.12rem 0 0;
        .tip_box_tit {
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 0.12rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.17rem;
          margin-right: 2.77rem;
        }
        .tip_box_text {
          // width: 20.75rem;
          height: 0.34rem;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 0.12rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          line-height: 0.17rem;
          margin-top: 0.08rem;
          margin-bottom: 0.24rem;
        }
      }
      }
  }
  .pay_button {
    width: 90%;
    border-radius: 0.04rem;
  }
}
</style>
